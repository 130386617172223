import React from "react"

import Layout from "../components/layout";
import FullPuzzle from "../components/full_puzzle";

const PuzzlePage = ({ pageContext }) => (
    <Layout>
	<FullPuzzle puzzle = {pageContext.puzzle} />
    </Layout>
)
export default PuzzlePage
