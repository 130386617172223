import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { withPrefix } from "gatsby"

// TODO: need to handle images

const FullPuzzle = ({ puzzle }) => (
    <div className={`puzzle ${puzzle.type}`}>
    <Link to={`/puzzles/${puzzle.id}`}>
      <h1>{<React.Fragment>
             {(puzzle.type === "birds") ?
              <img style={{marginBottom: 0, marginRight: `0.5rem`}}
                   src={withPrefix("/bird-icon.png")} width="40" height="40"/> : ""}
             {puzzle.title}
           </React.Fragment>
          }</h1>
    </Link>
      <div dangerouslySetInnerHTML={{ __html: puzzle.content.replace(/\n/g, "<br/>") }}/>
      { // I hate react; why can't I use a normal if statement
          ('images' in puzzle) ?
          puzzle.images.map(image => (<img src={withPrefix(`/${image}`)}/>))
          : ""
      }

    </div>
)
export default FullPuzzle
